import leaves from '../../images/hand_drawn_icons/leaves.png'
import clap from '../../images/hand_drawn_icons/clap.png'
import shapes from '../../images/hand_drawn_icons/shapes.png'
import okayHand from '../../images/hand_drawn_icons/okay-hand.png'
import target from '../../images/hand_drawn_icons/target.png'

export default {
  en: {
    royaltyText: 'Our values',
    title: 'Envato’s values drive our creative mission',
    values: [
      {
        icon: {
          src: leaves,
          alt: 'Leaves icon'
        },
        heading: 'Build our Future',
        body: 'We acknowledge the past while we build our future and seize opportunities to evolve and disrupt.'
      },
      {
        icon: {
          src: clap,
          alt: 'Clap icon'
        },
        heading: 'Community Spirit',
        body: 'We give considered thought to our whole community in our decisions, our actions, and desired outcomes. Our sense of community underpins everything.'
      },
      {
        icon: {
          src: shapes,
          alt: 'Shapes icon'
        },
        heading: 'Embrace Diversity',
        body: 'We thrive when we embrace diversity and inclusion, ensuring we cultivate a feeling of equity and belonging and acting with respect at all times.'
      },
      {
        icon: {
          src: okayHand,
          alt: 'Okay hand icon'
        },
        heading: 'Make Envato Proud',
        body: 'We work together to bring out the best in ourselves and each other. We aspire for quality in everything we do, learn from our failures, and celebrate our successes. We’ll make the Envato team proud.'
      },
      {
        icon: {
          src: target,
          alt: 'Target icon'
        },
        heading: 'Make It Happen',
        body: 'We maximise the impact of our performance and take accountability for what we say we’ll do. We act fast and make it happen.'
      }
    ]
  },
  es: {
    royaltyText: 'Nuestros Valores',
    title: 'En Envato, nuestros valores impulsan nuestra misión creativa',
    values: [
      {
        icon: {
          src: leaves,
          alt: 'Leaves icon'
        },
        heading: 'Construir nuestro futuro',
        body: 'Reconocemos el pasado mientras construimos el futuro y aprovechamos oportunidades para evolicionar y transformar.'
      },
      {
        icon: {
          src: clap,
          alt: 'Clap icon'
        },
        heading: 'Espíritu de comunidad',
        body: 'Tomamos en cuenta a nuestra comunidad entera para la toma de decisiones, nuestras acciones, y los resultados deseados. Nuestro sentido de comunidad está por encima de todo.'
      },
      {
        icon: {
          src: shapes,
          alt: 'Shapes icon'
        },
        heading: 'Abrazamos la diversidad',
        body: 'Prosperamos cuando abrazmos la diversidad e inclusión, asegurándo que cultivamos un sentimiento de equidad y pertenencia y actuando con respeto en todo momento.'
      },
      {
        icon: {
          src: okayHand,
          alt: 'Okay hand icon'
        },
        heading: 'Hacemos Orgulloso a Envato',
        body: 'Trabajamos juntos para sacar lo mejor de nosotros y de los demás. Apuntamos hacia la calidad en todo lo que hacemos, aprendemos de nuestros fracasos, y celebramos nuestros éxitos. Haremos que el equipo de Envato se sienta orgulloso.'
      },
      {
        icon: {
          src: target,
          alt: 'Target icon'
        },
        heading: 'Lo hacemos que suceda.',
        body: 'Maximisamos el impacto de nuestro desempeño y tomamos responsabilidad por lo que decimos que haremos. Actuamos rápido y hacemos que las cosas sucedan.'
      }
    ]
  }
}
