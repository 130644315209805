import React from 'react'
import { Analytics } from '@envato/google-analytics'
import Figma from '../figma/figma'
import Title from '../title/title'
import ValuesCard from '../values_card/values_card'
import { useTranslation } from '../../i18n'
import translations from './values_section.translations'
import styles from './values_section.module.css'

const ValuesSection = ({ background }) => {
  const t = useTranslation(translations)

  return (
    <Analytics.Section trackingId='values'>
      <Figma.Section background={background}>
        <Title royaltyText={t.royaltyText} titleText={t.title} />
        <div className={styles.cards}>
          {t.values.map((value) => (
            <ValuesCard image={value.icon} title={value.heading} description={value.body} />
          ))}
        </div>
      </Figma.Section>
    </Analytics.Section>
  )
}

ValuesSection.propTypes = {
  background: Figma.Section.propTypes.background
}

export default ValuesSection
