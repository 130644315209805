import React from 'react'
import PropTypes from 'prop-types'
import styles from './values_card.module.css'

const ValuesCard = ({ image, title, description }) => {
  return (
    <div className={styles.root}>
      {image && (
        <div className={styles.image}>
          <img loading='lazy' src={image.src} alt={image.alt} />
        </div>
      )}
      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.text}>{description}</p>
      </div>
    </div>
  )
}

ValuesCard.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string
  }),
  title: PropTypes.any.isRequired,
  description: PropTypes.string.isRequired
}

export default ValuesCard
